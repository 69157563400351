export const contactData = [
    {
        icon: 'whatsapp.svg',
        link: 'https://wa.me/79268567667?text=Hello%21%20I%27d%20like%20to%20place%20an%20order.',
    },
    {
        icon: 'instagram.svg',
        link: 'https://www.instagram.com/ser_project_',
    },
    {
        icon: 'telegram.svg',
        link: 'https://t.me/Kuznetsov_Ser',
    },
    {
        icon: 'vk.svg',
        link: 'https://instagram.com/',
    },
    {
        icon: 'mail.svg',
        link: 'mailto:Serproject310@gmail.com',
    },
    {
        icon: 'youtube.svg',
        link: 'https://www.youtube.com/@ser_project_/featured',
    },
];

export const priceData = [
    {
        title: 'Базовый',
        price: '5000₽/м²',
        list: [
            'Замер',
            'Планировочные решения',
            '3D-визуализация основных помещений',
            'Рабочая документация',
        ],
    },
    {
        title: 'Стандартный',
        price: '8000₽/м²',
        list: [
            'Замер',
            'Планировочные решения',
            '3D-визуализация всех комнат',
            'Рабочая документация',
            'Спецификация мебели',
            'Работа с подрядчиками',
        ],
    },
    {
        title: 'Премиум',
        price: '15000₽/м²',
        list: [
            'Замер',
            'Планировочные решения',
            '3D-визуализация всех комнат',
            'Рабочая документация',
            'Спецификация мебели',
            'Работа с подрядчиками',
            'Заказ и контроль поставки мебели',
            'Выезд на подбор материалов',
            'Контроль и проверка подрядчиков',
            'Ведение проекта до завершения',
        ],
    },
    {
        title: 'Архитектурный проект',
        price: '4000₽/м²',
        list: [
            'Замер',
            'Планировочные решения',
            '3D-визуализация',
            'Рабочая документация',
            'Работа с подрядчиками',
            'Спецификация декора и аксессуаров',
            'Сопровождение в закупке декоративных материалов',
            'Ведение проекта до завершения',
            'Дополнительная оплата :',
            'Конструктивные проекты',
            'Инженерные проекты',
        ],
    },
];

export const serviceData = [
    {
        img: '1.png',
        label: 'Интерьеры',
        text: 'Мы проектируем интерьеры больших и маленьких квартир, ресторанов и общественных зон. Мы знаем, как создать уют в любом помещении.',
    },
    {
        img: '2.png',
        label: 'Архитектура частных домой',
        text: 'Мы делаем Архитектурные проекты частных домов любого размера и планировки. Создаем уютную обстановку.',
    },
    {
        img: '3.png',
        label: 'Авторский надзор',
        text: 'Мы заботимся обо всем, что связано с проектом: будь то подрядчики по строительству или инженерные работы.',
    },
];

export const stepsData = [
    {
        img: '1.png',
        title: 'Предварительный эскиз',
        text: 'Определение стилистического решения интерьера, подготовка технических заданий, планировочных решений, коллажей для всех помещений.',
    },
    {
        img: '2.png',
        title: 'Визуализация проекта',
        text: 'Детальная визуализация каждой комнаты. 3D-изображения учитывают ранее выбранные элементы интерьера и отделочные материалы.',
    },
    {
        img: '3.png',
        title: 'Рабочая документация',
        text: 'Создание рабочей документации для проекта дизайна интерьера: спецификации и планировочное решение с мебелью.',
        list: [
            'План с расстановкой мебели и оборудованием',
            'План с привязкой и маркировкой мебели',
            'План монтаж/демонтаж (по необходимости)',
            'План полов',
            'План потолков',
            'План освещения',
            'План с расстановкой выключателей и маркировкой осветительных приборов',
            'План с расстановкой силовых и слаботочных выводов',
            'Схема выводов воды и канализации',
            'Схема отопления',
            'Схема вентиляции и кондиционирования',
            'Схема типов настенных материалов',
            'Узлы строительные на полы, потолки и прочие сложные элементы требующие ответственного подхода строителей (могут находится на листах с планами)',
            'Развертки по всем стена со схематичным указанием деталей и размерами',
            'Индивидуальные изделия (количество листов с изделиями зависит от количества изделий в проекте)',
            'Спецификации:',
            'Спецификация мебели',
            'Спецификация сантехники',
            'Спецификация электрики',
            'Спецификация отделочных материалов',
            'Спецификация дверей и проемов',
            'Спецификация окон',
        ],
    },
    {
        img: '4.png',
        title: 'Авторский надзор',
        text: 'Контроль соответствия строительных работ проектному плану. Наш дизайнер и инженер регулярно посещают объект и все контролируют. Мы осуществляем и закупку материалов.',
    },
];
